import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { forgotPassword } from 'login/actions/forgotPassword';
import Form, { MailInput } from 'shared/components/form';
import useQuery from 'shared/hooks/useQuery';
import containsEmail from 'shared/util/containsEmail';
import { trackClick } from 'shared/util/tracking/mercury';
import { Button } from 'wiw/ui';
import FontIcon from 'wiw/ui/FontIcon';

import { useHistory } from 'react-router-dom';

import 'reactivate/assets/css/login.scss';

export default function Forgot({ resetMessage, type }) {

  Forgot.propTypes = {
    resetMessage: PropTypes.string,
    type: PropTypes.string,
  };

  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isValid, setisValid] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (getUrlEmail() && containsEmail(getUrlEmail())) {
      handleSubmit({ email: getUrlEmail() });
      query.delete('email');
      history.replace({
        search: query.toString(),
      });
    }
  }, []);

  const getUrlEmail = () => {
    return query.get('email');
  };

  const handleSubmit = data => {
    setSubmitting(true);
    return dispatch(forgotPassword(data.email, type))
      .then(() => {
        setEmail(data.email);
        setSubmitted(true);
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  const onBack = () => {
    history.goBack();
  };

  const setValid = () => {
    setisValid(true);
  };

  const setInvalid = () => {
    setisValid(false);
  };

  const renderForm = () => {
    return (
      <Fragment>
        <button
          className="btn arrow-left"
          type="button "
          onClick={ event => { trackClick(event.target.innerText); onBack(); } }>
          <FontIcon icon="arrow-left" /> back
        </button>
        <h3>Forgot Password?</h3>
        <Form
          className="row no-gutters"
          onValidSubmit={ handleSubmit }
          onValid={ setValid }
          onInvalid={ setInvalid }
        >
          <MailInput
            width={ 12 }
            name="email"
            validations="isEmail"
            inputProps={ { placeholder: 'Enter email...' } }
            value={ getUrlEmail() }
            disabled={ !!getUrlEmail() }
            validateOnBlur
            required />
          <Button
            color="gold"
            type="submit"
            className="forgot-btn"
            disabled={ !isValid }
            onClick={ event => trackClick(event.target.innerText) }
            loading={ submitting }>
            Send Reset Email
          </Button>
        </Form>
      </Fragment>
    );
  };

  const renderConfirmation = () => {
    return (
      <Fragment>
        <h3>Reset Password</h3>
        <h6>We sent a message to </h6>
        <h5>{ email }. </h5>
        <h6>{ resetMessage }</h6>
      </Fragment>
    );
  };

  return (
    <div className="row reactivate-content">
      <div className="col">
        { !submitted ? renderForm() : renderConfirmation() }
      </div>
    </div>
  );
}
