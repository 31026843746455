import PhoneUtil from 'shared/util/phoneutil';

import { PhoneNumberUtil } from 'google-libphonenumber';

export default (country: string, message = 'Please enter a valid phone number') => (value: string): boolean | string => {
  if (!value) {
    return true;
  }
  return PhoneUtil.isValidNumber(value, country) || message;
};

export const isPhoneNumberWithoutCountry = (message = 'Please enter a valid phone number.') => (value: string): boolean | string => {
  if (!value) {
    return true;
  }

  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    const parsed = value.startsWith('+') ? phoneUtil.parse(value) : phoneUtil.parse(value, 'US');
    return phoneUtil.isValidNumber(parsed) || message;
  } catch (error) {
    if (error.message.includes('Invalid country calling code')) {
      return 'Please enter a valid country code.';
    }
    return message;
  }
};
