import { ForwardedRef, forwardRef, ReactNode, useEffect, useImperativeHandle } from 'react';

import { DevTool } from '@hookform/devtools';
import {
  DefaultValues,
  FormProvider,
  FormState,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  ValidationMode,
} from 'react-hook-form';

export type FormProps = {
  onSubmit: SubmitHandler<any>;
  onError?: SubmitErrorHandler<any>,
  children(props: FormState<any>): ReactNode;
  defaultValues?: DefaultValues<any>,
  debug?: boolean,
  validate?: keyof ValidationMode | 'onBlur',
}

const defaultProps = {
  debug: false,
};

function Form(props: FormProps, ref: ForwardedRef<any>) {
  props = { ...defaultProps, ...props };

  const methods = useForm({
    defaultValues: props.defaultValues,
    mode: props.validate || 'onTouched',
  });

  useImperativeHandle(ref, () => ({
    formState: methods.formState,
    getValues: methods.getValues,
    reset: methods.reset,
    resetField: methods.resetField,
    setError: methods.setError,
    setValue: methods.setValue,
    trigger: methods.trigger,
    watch: methods.watch,
    setFocus: methods.setFocus,
    clearErrors: methods.clearErrors,
  }));

  useEffect(() => {
    if (props.defaultValues) {
      methods.reset(props.defaultValues);
    }
  }, [props.defaultValues]);

  const { children, debug, defaultValues, ...rest } = props;
  return (
    <FormProvider { ...methods }>
      <form { ...rest } ref={ ref } onSubmit={ methods.handleSubmit(props.onSubmit, props.onError) }>
        { children(methods.formState) }
      </form>
      { debug && <DevTool control={ methods.control } /> }
    </FormProvider>
  );
}

export default forwardRef(Form);
