import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Flag from './Flag';

import { components } from 'react-select';

export default function CustomSingleValue(props) {
  return (
    <components.SingleValue { ...props }>
      <Flag { ...props } />
    </components.SingleValue>
  );
}
