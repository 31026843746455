import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import resetPassword from 'data/person/actions/resetPassword';
import Person from 'data/person/model';
import PasswordFields from 'shared/components/PasswordFields';
import Form from 'shared/form';
import useQuery from 'shared/hooks/useQuery';
import { errorNotice } from 'wiw/notices';
import { Button } from 'wiw/ui';

import 'reactivate/assets/css/reset.scss';

export default function Reset({ urlBase, history }) {
  Reset.propTypes = {
    urlBase: PropTypes.string,
    history: PropTypes.object.isRequired,
  };

  const query = useQuery();
  const dispatch = useDispatch();

  const [tokenError, setTokenError] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (query.has('token')) {
      Person.checkResetToken({ token: query.get('token') })
        .catch(() => {
          setTokenError(true);
        });
    } else {
      toForgotPassword();
    }
  }, []);

  const toForgotPassword = () => {
    return history.push(`${urlBase}/forgot-password`);
  };

  const toLogin = () => {
    return history.push(`${urlBase}`);
  };


  const handleSubmit = data => {
    return dispatch(resetPassword({ token: query.get('token'), password: data.password }))
      .unwrap()
      .then(() => {
        setSuccess(true);
      })
      .catch(error => {
        dispatch(errorNotice(error.message, {
          listName: 'reset-notices',
        }));
      });
  };

  const renderInvalidToken = () => {
    return <div className="row invalid-token">
      <div className="col">
        <p>The password reset link is no longer valid.</p>
        <Button color="gold" onClick={ toForgotPassword }>Get Another Link</Button>
      </div>
    </div>;
  };

  const renderSuccessfulReset = () => {
    return <div className="row invalid-token">
      <div className="col">
        <p>Your password has been reset.</p>
        <Button color="gold" onClick={ toLogin }>Login</Button>
      </div>
    </div>;
  };

  const renderForm = () => {
    return <Form className="row no-gutters" onSubmit={ handleSubmit } validate="onSubmit">
      { ({ isSubmitting }) => (
        <Fragment>
          <PasswordFields hint icon={ false } hideLabel showRequirementsAtStart email={ query.get('email') } />
          <Button
            color="gold"
            type="submit"
            loading={ isSubmitting }
          >
            Reset Password
          </Button>
        </Fragment>
      ) }
    </Form>;
  };

  let view = renderForm();
  if (tokenError) {
    view = renderInvalidToken();
  } else if (success) {
    view = renderSuccessfulReset();
  }

  return (
    <div className="row reactivate-content reset-password-pane">
      <div className="col">
        <h3>Reset Password</h3>
        { view }
      </div>
    </div>
  );
}
