import { OptionKeys, ValidationKeys } from 'shared/form/hooks/useInputRegister';
import { type InputProps, type TextareaProps } from 'shared/form/types';
import validators from 'shared/form/validators';

type ValidateObject = {
  validate: {
    [key: string]: any;
  };
} & { [key: string]: boolean };

const mapCustomValidator = (validateKey: string, rules: ValidateObject) => {
  // check if we can match the validator name to our custom validators
  // @ts-ignore
  const validator = validators[validateKey];
  if (typeof validator === 'function') {
    rules.validate[validateKey] = validator(rules.validate[validateKey]);
    return true;
  }
  return false;
};

const mapStringValidatorsToFuncs = (validator: string, rules: ValidateObject) => {
  if (typeof validator === 'string') {
    return mapCustomValidator(validator, rules);
  }
  return false;
};

export default function useMapCustomValidators(
  props: Record<string, InputProps<any> | TextareaProps<any>>,
  controlledInput = false,
) {
  // @ts-ignore
  const rules: ValidateObject = {
    validate: {},
  };

  if (controlledInput && Object.keys(props).some(key => ValidationKeys.includes(key))) {
    Object.keys(props).forEach(key => {
      if (ValidationKeys.includes(key)) {
        rules[key] = props[key];
      }
    });
  }

  if (!props.validate) {
    // nothing to do if there are no validators to map....
    return rules;
  }

  // attempt to map validators to functions if consumer passed string
  if (mapStringValidatorsToFuncs(props.validate, rules)) {
    return rules;
  }

  Object.keys(props.validate).forEach((validateKey: string): void => {
    if (!OptionKeys.includes(validateKey)) {
      rules.validate[validateKey] = props.validate[validateKey];
    }

    if (mapCustomValidator(validateKey, rules)) {
      return;
    }

    // @ts-ignore
    rules[validateKey] = props.validate[validateKey];
  });

  return rules;
}
