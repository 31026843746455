import zxcvbn from 'shared/util/zxcvbn';

export default (minScore = 3, message = '') => value => {
  if (!value) {
    return true;
  }

  const score = zxcvbn(value).score;

  if (score < minScore) {
    return message;
  }

  return true;
};
