import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Form, { Input } from 'shared/form';
import isEmail from 'shared/form/validators/isEmail';
import Button from 'wiw/ui/Button';
import FontIcon from 'wiw/ui/FontIcon';

class ForgotForm extends Component {

  state = {
    submitting: false,
    submitDisabled: true,
  };

  static propTypes = {
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Form className="login-form no-gutters" onSubmit={ this.props.handleSubmit }>
        { ({ isSubmitting }) => (

          <Fragment>
            <Input
              name="email"
              beforeAddon={ <FontIcon icon="email" /> }
              label="Email Address"
              validate={ {
                isEmail: isEmail(),
              } }
              initialFocus
              required
            />
            <Button
              type="submit"
              className="mt-4"
              loading={ isSubmitting }
            >
              Send Reset Link
            </Button>
          </Fragment>
        ) }
      </Form>
    );
  }
}

export default ForgotForm;
