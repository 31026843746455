import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { request } from 'shared/auth';
import { errorNotice } from 'wiw/notices';
import { Button, PanelBody } from 'wiw/ui';

import 'login/styles/sso.scss';

function ThirdPartyButton(props) {
  return (
    <img
      src={ props.image }
      className="btn-thirdparty"
    />
  );
}

ThirdPartyButton.propTypes = {
  image: PropTypes.string,
};

class SSOPage extends Component {

  static propTypes = {
    loaded: PropTypes.bool,
    loggedIn: PropTypes.bool,
    authorized: PropTypes.bool,
    errorNotice: PropTypes.func.isRequired,
  };

  oauthLogin = (provider, providerNice) => event => {
    event.preventDefault();


    request().get(`/sso/${ provider }/url`)
      .then(({ url }) => {
        window.location = url;
      })
      .catch(error => {
        this.props.errorNotice(`There was an error connecting to ${ providerNice }.`, {
          listName: 'sso-notices',
        });
      });
  };

  render() {
    return (
      <PanelBody title="Third-Party Connect">
        <div className="sso-list">
          <div className="row">
            <div className="col">
              <Button onClick={ this.oauthLogin('quickbooks', 'QuickBooks') } color="secondary">Quickbooks</Button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Button to="/sso" color="secondary">SAML</Button>
            </div>
          </div>
        </div>
      </PanelBody>
    );
  }
}

export default connect(
  state => ({
    loaded: state.bootstrap.completed,
    loggedIn: state.auth.loggedIn,
  }),
  dispatch => bindActionCreators({
    errorNotice,
  }, dispatch),
)(SSOPage);
