import { SUGGEST_INDUSTRY } from 'store/actionTypes';
import { errorNotice } from 'wiw/notices/actions';
import request from 'shared/request';

export function suggestIndustryRequest(params = {}) {
  return {
    type: SUGGEST_INDUSTRY.REQUEST,
    params,
  };
}

export function suggestIndustryFailed(error) {
  return {
    type: SUGGEST_INDUSTRY.FAILURE,
    payload: error,
  };
}

export function suggestIndustrySuccess(suggestions = [], industries = []) {
  return {
    type: SUGGEST_INDUSTRY.SUCCESS,
    suggestions,
    industries,
  };
}

export function suggestIndustry(place = {}) {
  return dispatch => {
    dispatch(suggestIndustryRequest());
    return Promise.all([
      request('api').get(`/industries/place?types=${place.placeType}`),
      request('api').get('/industries'),
    ])
      .then(([suggestions, industries]) => {
        dispatch(suggestIndustrySuccess(suggestions.industries, industries.industries));
        return { suggestions, industries };
      })
      .catch(error => {
        dispatch(suggestIndustryFailed(error));
        dispatch(errorNotice(error.data.error || 'There was an error joining the account. Please try again.'));
        return error;
      });
  };
}
