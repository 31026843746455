import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getZendeskToken } from 'login/actions';
import { exitApp } from 'store/actions/app';

import QueryString from 'query-string';
import { withRouter } from 'react-router-dom';

class ZendeskSSO extends Component {

  static propTypes = {
    bootstrap: PropTypes.object.isRequired,

    getZendeskToken: PropTypes.func.isRequired,
    exitApp: PropTypes.func.isRequired,

    history: PropTypes.object.isRequired,
  }

  UNSAFE_componentWillMount() {
    this.props.getZendeskToken()
      .then(data => {
        const query = QueryString.stringify({
          jwt: data.token,
          return_to: this.props.bootstrap.entry.return_to,
        });

        const url = `${CONFIG.ZENDESK_SSO_URL}?${query}`;
        this.props.exitApp(url);
      })
      .catch(() => {
        history.push('/');
      });
  }

  render() {
    return null;
  }

}

export default withRouter(connect(
  state => ({
    bootstrap: state.bootstrap,
  }),
  dispatch => bindActionCreators({
    getZendeskToken,
    exitApp,
  }, dispatch)
)(ZendeskSSO));
