/**
 * To avoid circular import problems, this file's imports should be kept very
 * lean. Do not put any code in here to initialize the store; that should be
 * kept in configureStore and set up elsewhere on bootstrap.
 */

import { useDispatch } from 'react-redux';

import type { WiwState, WiwStore } from 'configureStore';
import type { Action } from 'redux';
import type { ThunkAction, ThunkDispatch } from 'redux-thunk';

export type { WiwState, WiwStore } from 'configureStore';
export type RootState = WiwState;
export type WiwThunk<ReturnType = void> = ThunkAction<ReturnType,
  WiwState,
  unknown,
  Action<string>>;
export type WiwThunkDispatch = ThunkDispatch<WiwState, unknown, Action<string>>;
export type WiwGetState = () => WiwState;

// Create Redux global store
let store: WiwStore;

export function initStore(newStore: WiwStore) {
  store = newStore;
}

/**
 * Get the current Redux state.
 */
export function getState() {
  return store.getState() as WiwState;
}

/**
 * DO NOT CALL THIS! You don't need to get the store directly. You only
 * need to get the state, so call getState() instead.
 *
 * (This function exists so that our app root can pass this store to the main
 * Redux provider. In similar situations, if you do actually know what you're
 * doing, you can cast this back from `unknown` to the correct type.)
 */
export function getStore(): unknown {
  return store;
}

export function useThunkDispatch() {
  return useDispatch<WiwThunkDispatch>();
}
