import { useEffect, useState } from 'react';

import moment from 'moment';

export default duration => {

  let countdown = duration;

  const buildCountdownString = timeLeft => {
    const timerComponents = {
      days: timeLeft.days(),
      hours: timeLeft.hours(),
      minutes: timeLeft.minutes(),
      seconds: timeLeft.seconds(),
    };

    return Object.keys(timerComponents)
      .map(component => {
        if (timerComponents[component] > 0) {
          return `${timerComponents[component]} ${component}`;
        }
      })
      .filter(Boolean)
      .shift();
  };
  const [countdownString, setCountdownString] = useState('');

  const calculateTimeLeft = () => {
    return moment.duration(countdown -= 1, 'seconds');
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft().asSeconds());

  useEffect(() => {
    if (duration > 0) {
      const timer = setInterval(() => {
        const timeLeft = calculateTimeLeft();
        setTimeLeft(timeLeft.asSeconds());
        setCountdownString(buildCountdownString(timeLeft));
      }, 1000);

      if (timer && duration === 0) {
        clearInterval(timer);
      }
      return () => clearInterval(timer);
    }
  }, [duration]);

  return [timeLeft, countdownString];
};
