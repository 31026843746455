import request from 'shared/request';

export function getZendeskToken() {
  return () => {
    return request().get('/zendesk')
      .catch(error => {
        throw error;
      });
  };
}
