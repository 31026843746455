import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import attemptLogin from 'data/auth/actions/login';
import { bootstrap } from 'data/bootstrap/actions/bootstrap';
import { getToken } from 'shared/auth';
import Form, { Input } from 'shared/components/form';
import useQuery from 'shared/hooks/useQuery';
import { trackClick } from 'shared/util/tracking/mercury';
import { Button } from 'wiw/ui';

import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import 'reactivate/assets/css/login.scss';

export default function Login({ resetPasswordURL }) {

  Login.propTypes = {
    resetPasswordURL: PropTypes.string,
  };

  const passwordInput = useRef(null);

  const [isValid, setisValid] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [attemptNumber, setAttemptNumber] = useState(0);

  const history = useHistory();
  const dispatch = useDispatch();

  const query = useQuery();

  useEffect(() => {
    dispatch(bootstrap(getToken()));
  }, []);

  useEffect(() => {
    if (passwordInput && getUrlEmail()) {
      passwordInput.current.focus();
    }
  }, [passwordInput]);

  const handleSubmit = data => {
    setSubmitting(true);

    return dispatch(attemptLogin({ data, history, attemptNumber }))
      .then(() => {
        setAttemptNumber(attemptNumber + 1);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  const getUrlEmail = () => {
    return query.get('email');
  };

  const toResetPassword = () => {
    return history.push(`${resetPasswordURL}${location.search}`);
  };

  const setValid = () => {
    setisValid(true);
  };

  const setInvalid = () => {
    setisValid(false);
  };

  return (
    <div className="row reactivate-content login-pane">
      <div className="col">
        <h3>Welcome Back!</h3>
        <Form
          className="row no-gutters"
          onValidSubmit={ handleSubmit }
          onValid={ setValid }
          onInvalid={ setInvalid }
        >
          <label htmlFor="email" className="sr-only">Email</label>
          <Input
            width={ 12 }
            name="email"
            validations="isEmail"
            autoCapitalize="none"
            inputProps={ {
              placeholder: 'Enter email...',
              className: classNames({ 'is-valid': !!getUrlEmail() }),
            } }
            validationErrors={ { isEmail: 'Enter a valid email.' } }
            value={ getUrlEmail() }
            validateOnBlur
            required
          />
          <label htmlFor="password" className="sr-only">Password</label>
          <Input
            name="password"
            type="password"
            inputProps={ {
              placeholder: 'Enter password...',
              innerRef: passwordInput,
            } }
            required
          />
          <Button
            color="gold"
            type="submit"
            disabled={ !isValid }
            onClick={ event => trackClick(event.target.innerText) }
            loading={ submitting }>
            Log in
          </Button>
          <Button
            color="primary"
            className="forgot-password-btn"
            onClick={ event => { trackClick(event.target.innerText); toResetPassword(); } }
            loading={ submitting }>
            Forgot Password?
          </Button>
        </Form>
      </div>
    </div>
  );
}
