import Cookies from 'js-cookie';

const cookieName = 'passwordUpdate';
const defaultOptions = {
  domain: CONFIG.COOKIE_DOMAIN,
  expires: 7,
};

const getPasswordChangeCookie = () => {
  const cookie = Cookies.get(cookieName);

  if (cookie === undefined) {
    return false;
  }

  try {
    return JSON.parse(cookie) || false;
  } catch (e) {
    return false;
  }
};

const setPasswordChangeSuccess = (currentCookieValue?: any) => {
  if (currentCookieValue === undefined) {
    currentCookieValue = getPasswordChangeCookie();
  }

  if (!currentCookieValue) {
    return false;
  }

  Cookies.set(cookieName, JSON.stringify({
    route: currentCookieValue.route,
    success: true,
  }), { ...defaultOptions });
};

/**
 * Starts a password change request using the new login flow, will default to redirecting to the login page if the
 * redirect param is not set to false
 */
const completePasswordChange = () => {
  const currentCookieValue = getPasswordChangeCookie();
  if (currentCookieValue) {
    setPasswordChangeSuccess(currentCookieValue);
  }

  if (currentCookieValue.route) {
    return window.location.assign(`${ CONFIG.FRONTEND_URL }${ currentCookieValue.route }`);
  }
  return window.location.assign(`${ CONFIG.FRONTEND_URL }`);
};

const clearPasswordChangeCookie = () => {
  Cookies.remove('passwordUpdate', { ...defaultOptions });
};

export default {
  completePasswordChange,
  clearPasswordChangeCookie,
  getPasswordChangeCookie,
};
