import PropTypes from 'prop-types';

import { components } from 'react-select';

export default function CustomMenu(props) {
  return (
    <components.Menu { ...props }>
      { props.children }
    </components.Menu>
  );
}
CustomMenu.propTypes = {
  children: PropTypes.node,
};


