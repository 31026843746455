import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import attemptLogin from 'data/auth/actions/login';
import { Divider } from 'shared/components';
import PasswordFields from 'shared/components/PasswordFields';
import Form, { Input } from 'shared/form';
import isEmail from 'shared/form/validators/isEmail';
import { Button, PanelBody } from 'wiw/ui';
import FontIcon from 'wiw/ui/FontIcon';

export default function Verify(props) {
  Verify.propTypes = {
    verifyEmail: PropTypes.string,
  };

  const dispatch = useDispatch();

  const handleSubmit = data => {
    return dispatch(attemptLogin({ data })).unwrap();
  };

  return (
    <div className="login">
      <PanelBody>
        <p className="mb-4">
          We found a When I Work account using the same email as the third-party account you connected with.
          Log in to verify you have access.
        </p>
        <Form className="login-form no-gutters" onSubmit={ handleSubmit } validate="onSubmit">
          {({ isSubmitting }) => (
            <Fragment>
              <Input
                name="email"
                beforeAddon={ <FontIcon icon="email" /> }
                label="Email Address"
                validate={ {
                  isEmail: isEmail(),
                } }
                value={ props.verifyEmail }
                required
              />
              <PasswordFields initialFocus alwaysHideRequirements />
              <div className="row">
                <div className="col">
                  <Button
                    type="submit"
                    loading={ isSubmitting }
                    className="btn-login"
                  >
                    Verify
                  </Button>
                </div>
              </div>

              <Divider />

              <div className="row">
                <div className="col">
                  <Button to="/forgot" color="secondary">Forgot Password?</Button>
                </div>
              </div>
            </Fragment>
          )}
        </Form>
      </PanelBody>
    </div>
  );
}
