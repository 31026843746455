import { ChangeEventHandler, Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

import { getAuthUser } from 'shared/auth';
import { Input } from 'shared/form';
import containsString from 'shared/form/validators/containsString';
import hasBeenPwned from 'shared/form/validators/hasBeenPwned';
import passwordScore from 'shared/form/validators/passwordScore';
import zxcvbn from 'shared/util/zxcvbn';
import FontIcon from 'wiw/ui/FontIcon';

import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

import 'shared/assets/styles/passwordFields.scss';

type PasswordFieldsProps = {
  password?: string,
  change?: boolean,
  hint?: boolean,
  icon?: boolean,
  hideLabel?: boolean,
  initialFocus?: boolean,
  baseValidation?: boolean,
  alwaysHideRequirements?: boolean,
  showRequirementsAtStart?: boolean,
  email?: string,
  clearable?: boolean,
  showHideIcon?: boolean,
}

const PasswordFields = ({
  password,
  change,
  hint,
  icon = true,
  hideLabel,
  baseValidation,
  initialFocus,
  alwaysHideRequirements,
  showRequirementsAtStart,
  email,
  clearable,
  showHideIcon = false,
}: PasswordFieldsProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    email: false,
    simple: false,
    pwned: false,
  });
  const [showRequirements, setShowRequirements] = useState(showRequirementsAtStart);
  const [focused, setFocused] = useState(initialFocus);
  const formContext = useFormContext();

  const authUser = useSelector(getAuthUser);

  const emailToCheck = email || formContext.getValues()?.email || authUser?.email;

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowRequirements = () => {
    setFocused(true);
    setShowRequirements(true);
  };

  const passwordLabel = change ? 'Current Password' : 'Password';
  const hintText = 'A phrase of three or more words is safe and easy to remember.';

  const showHideButton = () => {
    const hideDisplay = showHideIcon ? <FontIcon icon="not-visible" srText="Hide password" /> : 'Hide';
    const showDisplay = showHideIcon ? <FontIcon icon="visible" srText="Show password" /> : 'Show';

    return <button type="button" className="password-view-toggle" onClick={ togglePassword }>
      { showPassword ? hideDisplay : showDisplay }
    </button>;
  };

  const validatePassword = () => {
    const baseOptions = {};

    if (baseValidation) {
      return baseOptions;
    }

    let options = {
      hasBeenPwned: hasBeenPwned('You must meet all of the password requirements'),
      minLength: { value: 9, message: 'You must meet all of the password requirements' },
      maxLength: { value: 65, message: 'You must meet all of the password requirements' },
      tooSimple: passwordScore(3, 'You must meet all of the password requirements'),
    };

    const emailParts = emailToCheck?.split('@');
    const firstPart = emailParts ? emailParts[0] : '';

    if (firstPart !== '') {
      options = {
        ...options,
        // @ts-ignore
        containsEmail: containsString(firstPart, 'You must meet all of the password requirements'),
      };
    }
    return { ...baseOptions, ...options };
  };

  const checkPwnedStatus: ChangeEventHandler<HTMLInputElement> = async e => {
    if (!alwaysHideRequirements) {
      setFocused(false);

      const value = e.currentTarget.value;
      const passwordSafe = await hasBeenPwned('')(value);
      setPasswordRequirements(prevState => ({
        ...prevState, ...{ pwned: !passwordSafe },
      }));
    }
  };

  const checkRequirements = async (_: string, value: string) => {
    // @ts-ignore
    const score = zxcvbn(value).score;

    const emailParts = emailToCheck?.split('@');
    const firstPart = emailParts ? emailParts[0] : '';

    setPasswordRequirements(prevState => ({ ...prevState, ...{
      length: value?.length >= 10 && value?.length <= 64,
      email: value?.length > 0 && firstPart && !value.includes(firstPart),
      simple: score >= 3,
    } }));
  };

  const renderRequirements = () => {
    const lengthMet = passwordRequirements.length;
    const emailMet = passwordRequirements.email;
    const simpleMet = passwordRequirements.simple;
    const pwnedMet = passwordRequirements.pwned;
    const invalid = formContext?.getFieldState('password')?.invalid;

    const lengthIcon = invalid && !lengthMet ? 'close-circled' : (lengthMet ? 'checkmark-circled-filled' : 'circle-open');
    const emailIcon = invalid && !emailMet ? 'close-circled' : (emailMet ? 'checkmark-circled-filled' : 'circle-open');
    const simpleIcon = invalid && !simpleMet ? 'close-circled' : (simpleMet ? 'checkmark-circled-filled' : 'circle-open');
    const pwnedIcon = pwnedMet ? 'alert' : (!pwnedMet ? 'checkmark-circled-filled' : 'circle-open');
    const lengthText = formContext.getValues().password?.length <= 64 ? 'Be at least 10 characters' : 'Be at least 10 characters and not more than 64';

    return (
      <div className="password-requirements">
        Password must:
        <span className={ classNames('requirement-item', {
          'met': lengthMet,
          'not-met': !lengthMet && !invalid,
          'invalid': !lengthMet && invalid,
        }) }>
          <FontIcon icon={ lengthIcon } /> { lengthText } <span
            className="requirement-hint">(Currently { formContext.getValues()?.password?.length || 0 }</span>)
        </span>
        <span className={ classNames('requirement-item', {
          'met': emailMet,
          'not-met': !emailMet && !invalid,
          'invalid': !emailMet && invalid,
        }) }>
          <FontIcon icon={ emailIcon } /> Not contain your email address
        </span>
        <span className={ classNames('requirement-item', {
          'met': simpleMet,
          'not-met': !simpleMet && !invalid,
          'invalid': !simpleMet && invalid,
        })
        }>
          <FontIcon icon={ simpleIcon } /> Not be too simple or easy to guess
        </span>
        <span className={ classNames('requirement-item', {
          'invalid': pwnedMet,
          'not-met': !pwnedMet && !invalid,
          'd-none': !pwnedMet,
        })
        }>
          <FontIcon icon={ pwnedIcon } /> Not be found in a database of compromised passwords.
        </span>
      </div>
    );
  };

  const renderConfirmation = () => {
    return (
      <div className="password-confirmation col-12">
        <div className="row no-gutters">
          <Input
            label="New Password"
            name="password"
            type={ showPassword ? 'text' : 'password' }
            value={ password }
            // @ts-ignore
            required={ change }
            inputProps={ {
              'data-private': true,
            } }
            beforeAddon={ <FontIcon icon="lock" /> }
            afterAddon={ showHideButton() }
            hint={ hint ? hintText : '' }
            onFocus={ toggleShowRequirements }
            onChange={ checkRequirements }
            validate={ validatePassword() }
            onBlur={ checkPwnedStatus } />
        </div>
      </div>
    );
  };

  if (change) {
    return (
      <div className="password-fields row no-gutters">
        <Input
          type={ showPassword ? 'text' : 'password' }
          name="old_password"
          id="old_password"
          // @ts-ignore
          label={ !hideLabel && passwordLabel }
          value={ password }
          beforeAddon={ icon && <FontIcon icon="lock" /> }
          hint={ hint ? hintText : '' }
          inputProps={ {
            'data-private': true,
          } }
          initialFocus={ initialFocus }
          required
        />
        { renderConfirmation() }
        { showRequirements && renderRequirements() }
      </div>
    );
  } else {
    const clearButton = <button type="button" className="password-clear" onMouseDown={ e => e.preventDefault() }
      onClick={ () => formContext.setValue('password', '') }>
      <FontIcon icon="close-circled" />
    </button>;

    const shouldShowClear = clearable && focused && (password?.length || formContext.getValues()?.password?.length);
    const afterAddon = <Fragment>{!!shouldShowClear && clearButton}{showHideButton()}</Fragment>;

    return (
      <div className="password-fields row no-gutters">
        <Input
          type={ showPassword ? 'text' : 'password' }
          name="password"
          id="password"
          // @ts-ignore
          label={ !hideLabel && passwordLabel }
          value={ password }
          beforeAddon={ icon && <FontIcon icon="lock" /> }
          afterAddon={ afterAddon }
          hint={ hint ? hintText : '' }
          inputProps={ {
            'data-private': true,
          } }
          initialFocus={ initialFocus }
          required
          onFocus={ toggleShowRequirements }
          onChange={ checkRequirements }
          validate={ validatePassword() }
          onBlur={ checkPwnedStatus }
        />
        { !alwaysHideRequirements && showRequirements && renderRequirements() }
      </div>
    );
  }
};

export default PasswordFields;
