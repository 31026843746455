import Login from 'shared/api/login';

import { createAsyncThunk } from '@reduxjs/toolkit';

export default createAsyncThunk(
  'person/resetPassword',
  async ({ token, password }, { rejectWithValue, dispatch }) => {
    try {
      return await Login.post('/reset', { token, password });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
