import { createRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';

function InputWrapper(props) {

  const wrapperRef = createRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return function cleanup() {
      document.removeEventListener('mousedown', handleClickOutside);

    };
  }, []);

  const handleClickOutside = event => {
    if (props.onClickOutside && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      props.onClickOutside();
    }
  };

  const renderLabel = () => {
    if (!props.label) {
      return null;
    }

    return (
      <label className="form-control-label" htmlFor={ props.inputId ?? props.name }>
        { props.label }
        { props.labelAddon }
        { props.labelSmall && <small> { props.labelSmall }</small> }
        { props?.required && '*' }
      </label>
    );
  };

  const renderAddon = (className, displayErrorMessage) => {
    return (
      <fieldset className={ className }>
        { props.prepend }
        { renderLabel() }
        <div className="input-group mb-2 mr-sm-2 mb-sm-0" ref={ wrapperRef }>
          { props.beforeAddon && <div className="input-group-prepend">
            <div className="input-group-text">{ props.beforeAddon }</div>
          </div> }
          { props.children }
          { props.afterAddon && <div className="input-group-append">
            <div className="input-group-text">{ props.afterAddon }</div>
          </div> }
        </div>
        { displayErrorMessage && <div className="form-control-feedback">{ displayErrorMessage }</div> }
        { (props.hint && !displayErrorMessage) &&
          <small className={ classnames('form-text text-muted', props.hintClassname) }>{ props.hint }</small>
        }
        { props.append }
      </fieldset>
    );
  };

  const { getFieldState } = useFormContext();

  const errorMessage = getFieldState(props.name).error;
  const displayErrorMessage = errorMessage?.message;

  const className = classnames(
    'form-group',
    props.classNames,
    {
      'col': props.width === false,
      [`col-${ props.width }`]: props.width,
      'focus': props.focus,
      'is-invalid': errorMessage,
      'disabled': props.disabled,
      'newStyle': props.newStyle,
    },
  );

  if (props.beforeAddon || props.afterAddon) {
    return renderAddon(className, displayErrorMessage);
  }

  const fieldsetProps = {
    className,
    ref: wrapperRef,
  };

  if (props.id) {
    fieldsetProps.id = props.id;
  }

  return (
    <fieldset { ...fieldsetProps }>
      { props.prepend }
      { renderLabel() }
      { props.children }
      { displayErrorMessage && <div className="form-control-feedback">{ displayErrorMessage }</div> }
      { (props.hint && !errorMessage) &&
        <small className={ classnames('form-text text-muted', props.hintClassname) }>{ props.hint }</small>
      }
      { props.append }
    </fieldset>
  );
}

InputWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  inputId: PropTypes.string,
  classNames: PropTypes.string,
  label: PropTypes.any,
  labelAddon: PropTypes.any,
  labelSmall: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
  focus: PropTypes.bool,
  disabled: PropTypes.bool,
  newStyle: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string,  PropTypes.oneOf(['auto'])]),
  children: PropTypes.node.isRequired,
  beforeAddon: PropTypes.node,
  afterAddon: PropTypes.node,
  hint: PropTypes.string,
  hintClassname: PropTypes.string,
  onClickOutside: PropTypes.func,
  hasBlurred: PropTypes.bool,
  prepend: PropTypes.node,
  append: PropTypes.node,
  id: PropTypes.string,
  required: PropTypes.bool,
};

InputWrapper.defaultProps = {
  width: 12,
  disabled: false,
  newStyle: false,
  invalid: false,
  labelAddon: false,
  labelSmall: false,
  beforeAddon: false,
  afterAddon: false,
  hintClassname: '',
  validatePristine: false,
  validateOnBlur: false,
  hasBlurred: false,
};

export default InputWrapper;
