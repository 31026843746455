import { type TimezoneType } from 'data/timezones/types';

import { Map } from 'immutable';
import moment from 'moment-timezone';

export const getTimezone = (timezones: Map<number, TimezoneType>) => {
  const usertz = moment.tz.guess();
  let timezone = timezones.find(tz => {
    return tz.olsonId === usertz;
  });

  if (!timezone) {
    timezone = timezones.find(tz => {
      return tz.olsonId === 'America/Chicago';
    });
  }

  return timezone;
};
