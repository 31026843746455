import { MouseEventHandler } from 'react';

import FontIcon from '../../wiw/ui/FontIcon';

import 'register/styles/choose.scss';

type ChooseCardProps = {
  onClick: MouseEventHandler,
  header: string,
  body: string,
  image: string,
  imageAlt: string,
}
export default function ChooseCard({ onClick, header, body, image, imageAlt }:ChooseCardProps) {
  return (
    <div className="choose-card mb-4" onClick={ onClick }>
      <div className="row justify-content-between pl-3 pr-0">
        <p className="choose-card-header">{ header }</p>
        <FontIcon icon="chevron-right" />
      </div>
      <p className="choose-card-subheader">{ body }</p>
      <img src={ image } alt={ imageAlt } />
    </div>
  );

}
