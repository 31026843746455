import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { forgotPassword } from 'login/actions';
import { errorNotice } from 'wiw/notices/actions';
import { PanelBody } from 'wiw/ui';

import ForgotForm from '../components/ForgotForm';

import { Link } from 'react-router-dom';

import 'login/styles/forgotPassword.scss';


class ForgotPage extends Component {

  state = {
    email: '',
  };

  static propTypes = {
    forgotPassword: PropTypes.func.isRequired,
    errorNotice: PropTypes.func.isRequired,
  };

  componentDidMount() {
    setTimeout(() => this.setState({ open: true }), 100);
  }

  formSubmit = (data, reset, error) => {
    this.setState({ submitting: true });

    return this.props.forgotPassword(data.email)
      .then(() => {
        this.setState({ email: data.email });
      })
      .catch(error => {
        let message = 'Failed to send password reset request. Please try again later.';
        try {
          if (error.data.errors[0].code === 'SSO_ONLY') {
            message = (<Fragment>
              Your workplace does not allow you to reset your password. Please use single sign-on at <Link
                to="/sso">https://login.wheniwork.com/sso</Link> instead.
            </Fragment>);
          }
        } catch (e) {
        }

        this.props.errorNotice(message, {
          listName: 'forgot-password-notices',
        });
        this.setState({ submitting: false });
      });
  };

  renderPanel() {
    return (
      <PanelBody title="Forgot Your Password" className="pt-4">
        <ForgotForm
          submitting={ this.state.submitting }
          handleSubmit={ this.formSubmit }
        />
      </PanelBody>
    );
  }

  renderSuccess() {
    return (
      <PanelBody title="Password Sent!">
        <p className="text-center">
          We sent an email with instructions to reset your password to:
          <strong className="user-email">{ this.state.email }</strong>
        </p>
        <div className="spam-notice mb-0">
          <h5>Spam Filter Note</h5>
          <p>If you don't get an email from us within a few minutes, please check
            your spam filter. The email will be coming
            from:</p>
          <p><strong>noreply@wheniwork.com</strong></p>
        </div>
      </PanelBody>
    );
  }

  render() {
    return this.state.email ? this.renderSuccess() : this.renderPanel();
  }
}

export default connect(
  null,
  dispatch => bindActionCreators({
    forgotPassword,
    errorNotice,
  }, dispatch),
)(ForgotPage);
