import { SET_TEMP_ACCOUNT, SET_TEMP_USER } from 'store/actionTypes';

export function setPendingAccount(account) {
  return {
    type: SET_TEMP_ACCOUNT,
    payload: account,
  };
}

export function setPendingUser(user) {
  return {
    type: SET_TEMP_USER,
    payload: user,
  };
}

export { joinAccount } from './joinAccount';
export { suggestIndustry } from './getIndustrySuggestion';
export { getTimezones } from './getTimezones';
