import { REQUEST_JOIN } from 'store/actionTypes';
import Account from 'data/account/model';
import { errorNotice } from 'wiw/notices/actions';
import { setPendingUser } from 'search/actions';

export function joinAccountRequest(params = {}) {
  return {
    type: REQUEST_JOIN.REQUEST,
    params,
  };
}

export function joinAccountFailed(error) {
  return {
    type: REQUEST_JOIN.FAILURE,
    payload: error,
  };
}

export function joinAccountSuccess(payload = {}) {
  return {
    type: REQUEST_JOIN.SUCCESS,
    items: payload,
  };
}

export function joinAccount(id, history) {
  return dispatch => {
    dispatch(joinAccountRequest());
    return Account.register(id)
      .then(response => {
        dispatch(joinAccountSuccess(response));
        dispatch(setPendingUser(response.user));
        history.push('/join-confirm');
        return response;
      })
      .catch(error => {
        dispatch(joinAccountFailed(error));
        dispatch(errorNotice(error.data.error || 'There was an error joining the account. Please try again.', {
          listName: 'join-notices',
        }));
        return error;
      });
  };
}
