import { ReactElement, useEffect, useState } from 'react';

import InputWrapper from 'shared/form/inputs/InputWrapper';
import { InputProps, InputPropsI } from 'shared/form/types/InputProps';
import { propsToController } from 'shared/form/util/propsToController';
import FontIcon from 'wiw/ui/FontIcon';

import classnames from 'classnames';
import { isImmutable, Map } from 'immutable';
import { merge, omit } from 'lodash';
import { RegisterOptions, useController } from 'react-hook-form';
import ReactSelect, {
  ClearIndicatorProps,
  components,
  OptionProps,
  OptionsOrGroups,
  Props as ReactSelectProps,
} from 'react-select';
import Async from 'react-select/async';
import AsyncCreatable from 'react-select/async-creatable';
import Creatable from 'react-select/creatable';

const SelectType = {
  select: ReactSelect,
  creatable: Creatable,
  async: Async,
  asyncCreatable: AsyncCreatable,
};

type Options<Type> = Type | Type[] | Map<string | number, Type>;

interface SelectProps extends Omit<ReactSelectProps, 'name' | 'value' | 'options' | 'onChange'>,
  Omit<InputPropsI, 'type' | 'placeholder' | 'onFocus' | 'options' | 'onBlur' | 'className'> {
  type: 'select' | 'creatable' | 'async' | 'asyncCreatable',
  selectProps?: {
    [key: string]: any,
  },
  isInDialog?: boolean,
  clearable?: boolean,
  setFocus?: boolean,
  noWrapper?: boolean,
  formOptions?: RegisterOptions,
  options: OptionsOrGroups<Option, Group>,

  // you can add arbitrary properties
  [key: string]: any,
}

interface Group {
  label: string,
  options: Option[],
}

interface Option {
  [key: string]: any,
}

export default function Select(props: InputProps<SelectProps>) {

  const [focused, setFocused] = useState(false);

  const { field, formState } = useController(propsToController(props));

  useEffect(() => {
    if (props.value) {
      field.onChange(props.value);
    }
  }, [props.value]);

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  const getValue = () => {
    const { value, options } = props;
    let selectOptions = options;

    if (isImmutable(options)) {
      // @ts-ignore
      selectOptions = options.toArray();
    }

    if (value === null || typeof value === 'undefined') {
      return '';
    }

    if (Array.isArray(value)) {
      return value.map(value => selectOptions.find((option: Option) => {
        const optionValue = props.getOptionValue?.(option);
        return (optionValue == value || optionValue == value.value);
      }));
    }

    if (typeof value === 'object') {
      return value;
    }

    return props.options?.find((option: Option) => option.value == value);
  };

  const handleChange = (option: { name: string, value: string }): void => {
    if (props.isMulti) {
      field.onChange(option);
      props.onChange?.(props.name, option);
    } else {
      const value = option?.value || null;
      field.onChange(value);
      props.onChange?.(props.name, value);
    }
  };

  interface OptionType extends OptionProps {
    label: string,
    data: {
      className: string | null,
      addOn: ReactElement | null,
    },
    className?: string | undefined,
    renderOptionAddOns?: boolean,
  }

  const renderOption = (props: OptionType) => {
    const newProps: OptionType = {
      className: props.data.className ? props.data.className : undefined,
      ...props,
    };

    if (props.renderOptionAddOns) {
      return <components.Option { ...newProps }>
        { props.label }
        { props.data.addOn && <span className="pull-right">{ props.data.addOn }</span> }
      </components.Option>;
    }
    return <components.Option { ...newProps } />;
  };

  const renderClearIndicator = (props: ClearIndicatorProps) => {
    return (<components.ClearIndicator { ...props }>
      <FontIcon icon="close" />
    </components.ClearIndicator>);
  };


  const renderInput = () => {
    const inputClassname = classnames(
      props.className,
      'Select',
      {
        'form-control-danger': formState.errors[props.name],
      },
    );

    const SelectComponent = SelectType[props.type];
    const options = isImmutable(props.options) ? props.options.toArray() : props.options;

    const selectProps = omit(props, ['label', 'name', 'onChange', 'value', 'creatable', 'required']);

    // automatically detect if a dialog is open to portal selects
    const dialogKit = document.querySelector('.dialog-kit');

    if (props.isInDialog || dialogKit) {
      // @ts-ignore
      selectProps.menuPortalTarget = dialogKit;
      selectProps.menuPosition = 'fixed';
      selectProps.styles = { menuPortal: (base: any) => ({ ...base, zIndex: 200 }) };
    }
    return (<SelectComponent
      { ...selectProps }
      { ...field }
      options={ options }
      components={ merge(
        {
          Option: renderOption,
          ClearIndicator: renderClearIndicator,
        },
        props.components)
      }
      isClearable={ props.clearable || props.isClearable }
      isDisabled={ props.disabled || props.isDisabled }
      onChange={ handleChange }
      onFocus={ onFocus }
      onBlur={ onBlur }
      value={ getValue() }
      classNamePrefix="Select"
      className={ inputClassname }
    />);

  };

  if (props.noWrapper) {
    return renderInput();
  }

  return (
    <InputWrapper { ...props } { ...formState } focus={ focused }>
      { renderInput() }
    </InputWrapper>
  );
}

Select.defaultProps = {
  type: 'select',
  isInDialog: false,
  clearable: true,
  setFocus: false,
};
