import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import resetPassword from 'data/person/actions/resetPassword';
import Person from 'data/person/model';
import PasswordFields from 'shared/components/PasswordFields';
import Form from 'shared/form';
import { errorNotice } from 'wiw/notices';
import { PanelBody } from 'wiw/ui';
import Button from 'wiw/ui/Button';

import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

class ResetPasswordPage extends Component {

  state = {
    token: undefined,
    email: undefined,
    success: false,
    tokenError: false,
    checks: {
      tooShort: true,
      containsEmail: true,
      mismatch: true,
    },
    validationErrors: {},
    showPassword: false,

    submitting: false,
    disabled: true,
  };

  static propTypes = {
    location: PropTypes.object.isRequired,
    resetPassword: PropTypes.func.isRequired,
    errorNotice: PropTypes.func.isRequired,
  };

  UNSAFE_componentWillMount() {
    this.setState({
      token: queryString.parse(this.props.location.search).token,
      email: queryString.parse(this.props.location.search).email,
    }, () => {
      // Check immediately to make sure the reset token is valid
      Person.checkResetToken({ token: this.state.token }).catch(() => {
        this.setState({ tokenError: true });
      });
    });
  }

  componentDidMount() {
    setTimeout(() => this.setState({ open: true }), 100);
  }

  onSubmit = data => {
    this.setState({ submitting: true });

    return this.props.resetPassword({ token: this.state.token, password: data.password })
      .unwrap()
      .then(() => {
        this.setState({ success: true });
      })
      .catch(error => {
        this.setState({ submitting: false });
        this.props.errorNotice(error.message);
      });
  };

  renderForm = () => {
    return (
      <PanelBody title="Reset Password">
        <Form
          className="reset-form"
          onSubmit={ this.onSubmit }
          validate="onSubmit"
        >
          { ({ isSubmitting }) => (
            <Fragment>
              <PasswordFields hint email={ this.state.email } />
              <Button
                type="submit"
                size="lg"
                className="mt-3"
                loading={ isSubmitting }
              >Reset Password</Button>
            </Fragment>
          ) }
        </Form>
      </PanelBody>
    );
  };

  renderSuccess = () => {
    return (
      <PanelBody title="Success!">
        <p style={ { textAlign: 'center' } }>Your password has been reset.</p>
        <Button to="/">Log In</Button>
      </PanelBody>
    );
  };

  renderError = () => {
    return (
      <PanelBody title="Reset Password">
        <p style={ { textAlign: 'center' } }>The password reset link is no longer valid.</p>
        <Button to="/">Log In</Button>
      </PanelBody>
    );
  };

  render() {
    let view = this.renderForm();
    if (this.state.tokenError) {
      view = this.renderError();
    } else if (this.state.success) {
      view = this.renderSuccess();
    }

    return view;
  }
}

export default withRouter(connect(
  null,
  dispatch => bindActionCreators({
    resetPassword,
    errorNotice,
  }, dispatch),
)(ResetPasswordPage));
