import Immutable from 'immutable';
import request from 'shared/request';
import { camelizeKeys, decamelizeKeys } from 'humps';

export default class Timezones extends Immutable.Record({
  id: null,
  name: null,
  offset: null,
  olsonId: null,
}) {
  constructor(defaults) {
    super(camelizeKeys(defaults));
  }

  toSnake() {
    return decamelizeKeys(this.toJS());
  }

  static getAll() {
    return request('api').get('/timezones')
      .then(data => {
        return data.timezones;
      });
  }
}
