import Person from 'data/person/model';
import { FORGOT_PASSWORD } from 'store/actionTypes';

function forgotPasswordRequest(params = {}) {
  return {
    type: FORGOT_PASSWORD.REQUEST,
    params,
  };
}

function forgotPasswordFailed(error) {
  return {
    type: FORGOT_PASSWORD.FAILURE,
    payload: error,
  };
}

function forgotPasswordSuccess(payload = {}) {
  return {
    type: FORGOT_PASSWORD.SUCCESS,
    items: payload,
  };
}

export function forgotPassword(email, messageType) {
  return dispatch => {
    dispatch(forgotPasswordRequest());
    return Person.forgotPassword(email, messageType)
      .then(response => {
        dispatch(forgotPasswordSuccess(response));
      })
      .catch(error => {
        dispatch(forgotPasswordFailed(error));

        throw error;
      });
  };
}
