import { forEach, pick, result } from 'lodash';

const flattenAddressComponents = data => {
  const result = {};
  forEach(data.address_components, ({ types, ...rest }) => {
    forEach(types, type => {
      result[type] = pick(rest, ['long_name', 'short_name']);
    });
  });
  return result;
};

export const convertToPlace = data => {
  const components = flattenAddressComponents(data);
  return {
    businessName: data.name,
    address: data.formatted_address,
    subpremise: result(components.subpremise, 'long_name'),
    streetNumber: result(components.street_number, 'short_name'),
    streetName: result(components.route, 'short_name'),
    locality: result(components.postal_town, 'short_name') ||
    result(components.locality, 'short_name'),
    subLocality: result(components.sublocality, 'short_name'),
    region: result(components.administrative_area_level_1, 'short_name'),
    postalCode: result(components.postal_code, 'long_name'),
    country: result(components.country, 'short_name'),
    latitude: result(data.geometry, 'location.lat'),
    longitude: result(data.geometry, 'location.lng'),
    placeType: (data.types || []).join(','),
    placeId: data.place_id,
    formattedPhoneNumber: data.formatted_phone_number,
    internationalPhoneNumber: data.international_phone_number,
    website: data.website,
  };
};

export const getPlaceDetails = placeId => {
  // We have to create a dummy elm for places services
  const api = new google.maps.places.PlacesService(document.createElement('div'));
  return new Promise(resolve => {
    api.getDetails({ placeId }, data => {
      resolve(data);
    });
  });
};

export const getPlacePhoto = placeData => {
  if (('photos' in placeData) && placeData.photos.length) {
    const photoDetails = placeData.photos.shift();
    return {
      src: photoDetails.getUrl({ 'maxWidth': 320, 'maxHeight': 320 }),
      attributions: photoDetails.html_attributions.shift(),
      hasImage: true,
    };
  } else {
    return {
      src: null,
      attributions: null,
      hasImage: false,
    };
  }
};
