import LoginClient from 'shared/api/login';

export default (message = '') =>  async value => {
  if (!value) {
    return true;
  }

  try {
    const response = await LoginClient.post('/password/check', { password: value });
    if (!response?.data?.safe ?? false) {
      return message
    }
  } catch (e) {
      // Backend wasn't able to check, assume failed
      return message;
  }

  return true;
};
