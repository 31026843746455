import { FETCH_TIMEZONES } from 'store/actionTypes';
import Timezones from 'data/timezones/model';
import { errorNotice } from 'wiw/notices/actions';

export function getTimezonesRequest(params = {}) {
  return {
    type: FETCH_TIMEZONES.REQUEST,
    params,
  };
}

export function getTimezonesFailed(error) {
  return {
    type: FETCH_TIMEZONES.FAILURE,
    payload: error,
  };
}

export function getTimezonesSuccess(timezones = []) {
  return {
    type: FETCH_TIMEZONES.SUCCESS,
    timezones,
  };
}

export function getTimezones() {
  return dispatch => {
    dispatch(getTimezonesRequest());
    return Timezones.getAll()
      .then(timezones => {
        dispatch(getTimezonesSuccess(timezones));
        return timezones;
      })
      .catch(error => {
        dispatch(getTimezonesFailed(error));
        dispatch(errorNotice(error.data?.error || 'Failed to get a list of available timezones.'));
        return error;
      });
  };
}
