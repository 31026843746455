import { camelizeKeys, decamelizeKeys } from 'humps';
import Immutable from 'immutable';

class Industries extends Immutable.Record({
  id: 0,
  naicsCode: null,
  name: '',
  parent: 0,
  sicCode: null,
  sort: 0,
  createdAt: null,
  /** @type {Industries[]|null} */
  options: null,
}) {
  /**
   * Sort industries using the industry name.
   *
   * @param {boolean} ascending
   * @returns {function(Industries, Industries): number}
   */
  static sortByName(ascending = true) {
    return (a, b) => {
      const comparison = a.name.localeCompare(b.name);
      return ascending ? comparison : -1 * comparison;
    };
  }

  /**
   * Sort industries using the provided `sort` property.
   *
   * @param {boolean} ascending
   * @returns {function(Industries, Industries): number}
   */
  static sort(ascending = true) {
    return (a, b) => {
      const comparison = a.sort - b.sort;
      return ascending ? comparison : -1 * comparison;
    };
  }

  constructor(defaults) {
    super(camelizeKeys(defaults));
  }

  toSnake() {
    return decamelizeKeys(this.toJS());
  }

  toDropdown() {
    const output = {
      label: this.name,
      value: this.id,
    };
    // dont render children anymore LOGINJS-183
    // if (this.options) {
    //   output.options = this.options.map(option => option.toDropdown());
    // }
    return output;
  }

}

export default Industries;
