import { useEffect, useRef } from 'react';

import { InputRef } from 'shared/form/types';

export default function useAutoFocus(shouldFocus: boolean = false) {
  const ref: InputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (shouldFocus && ref.current) {
      ref.current.focus();
    }
  }, [ref]);

  return { ref };
}
