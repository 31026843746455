import Monolith from 'shared/api/monolith';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

export type FetchIndustriesParams = {
  set?: 'fre2024'
};

type IndustryFields = {
  id: number,
  naicsCode: string | null,
  name: string,
  parent: number,
  sicCode: string | null,
  sort: number,
  createdAt: string | null,
};

type IndustryFieldsWithChildren = IndustryFields & {
  children: IndustryFields[],
};

type IndustriesResponse = {
  industries: IndustryFieldsWithChildren[],
};

export default createAsyncThunk<IndustryFieldsWithChildren[], FetchIndustriesParams | undefined>(
  'industries/fetchIndustries',
  async (params, { rejectWithValue }) => {
    try {
      const response = await Monolith.get('/industries', { query: params }) as AxiosResponse<IndustriesResponse>;
      return response.data?.industries;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
