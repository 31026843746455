import { Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { samlLogin } from 'login/actions';
import Form, { Input } from 'shared/form';
import minLength from 'shared/form/validators/minLength';
import { errorNotice } from 'wiw/notices/actions';
import { Button, PanelBody } from 'wiw/ui';
import FontIcon from 'wiw/ui/FontIcon';

export default function SAMLPage(props) {
  SAMLPage.propTypes = {};

  const dispatch = useDispatch();

  const handleSubmit = data => {
    return dispatch(samlLogin(data.account_id))
      .catch(error => {
        dispatch(errorNotice(error.message));
      });
  };

  return (
    <PanelBody>
      <Form className="login-form no-gutters" onSubmit={ handleSubmit }>
        { ({ isSubmitting }) => (
          <Fragment>
            <Input
              id="login-sso-account"
              name="account_id"
              label="Account ID (or subdomain)"
              beforeAddon={ <FontIcon icon="workplace" /> }
              placeholder="e.g. companyname"
              validate={ {
                minLengths: minLength(3),
              } }
              initialFocus
              required
            />
            <Button
              type="submit"
              loading={ isSubmitting }
              size="lg"
            >
              Log In
            </Button>
          </Fragment>
        ) }</Form>
    </PanelBody>
  );
}
