import { ChangeEvent, useEffect, useState } from 'react';

import { useAutoFocus } from 'shared/form';
import CustomMenu from 'shared/form/components/PhoneInput/CustomMenu';
import CustomOption from 'shared/form/components/PhoneInput/CustomOption';
import CustomSingleValue from 'shared/form/components/PhoneInput/CustomSingleValue';
import { getRegionOptions } from 'shared/form/data/countryData';
import InputWrapper from 'shared/form/inputs/InputWrapper';
import Select from 'shared/form/inputs/Select';
import { InputProps, InputWrapperProps } from 'shared/form/types';
import { propsToController } from 'shared/form/util/propsToController';
import isPhoneNumber from 'shared/form/validators/isPhoneNumber';

import classnames from 'classnames';
import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { defaultsDeep } from 'lodash';
import { useController } from 'react-hook-form';

import 'flag-icon-css/css/flag-icon.min.css';
import 'shared/form/styles/PhoneInput.scss';

const phoneUtil = PhoneNumberUtil.getInstance();
const regionOptions = getRegionOptions();

export default function PhoneInput(props: InputProps & InputWrapperProps) {
  const [focused, setFocused] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [rawInput, setRawInput] = useState('');
  const { ref } = useAutoFocus(props.initialFocus);

  const optionMapping = propsToController(props);

  const options = defaultsDeep(optionMapping, {
    rules: {
      validate: {
        isPhone: isPhoneNumber(selectedRegion),
      },
    },
  });
  const { field, formState } = useController(options);

  useEffect(() => {
    setSelectedRegion(regionOptions[3].value);
  }, []);

  useEffect(() => {
    updateWithInput(field.value, true);
  }, [field.value]);

  const onFocus = (e: ChangeEvent<HTMLInputElement>) => {
    setFocused(true);
    props.onFocus?.(e);
  };

  // Gets the phone code of the currently selected region.
  const getCurrentCode = (): string => {
    const currentRegionInfo = regionOptions.find(opt => opt.value === selectedRegion);
    return currentRegionInfo?.code || '';
  };

  // Checks whether the number is valid. It will attempt to guess the region based
  // on properties of the number itself, but if that fails, it will fall back to
  // the currently selected region.
  const isValid = (num: PhoneNumber): string | boolean => {
    // First check if the number has enough region info baked in to let us
    // check directly against that.
    const inherentRegion = phoneUtil.getRegionCodeForNumber(num);
    if (inherentRegion && phoneUtil.isValidNumberForRegion(num, inherentRegion)) {
      return inherentRegion;
    }

    // Otherwise, check against the selected region.
    if (phoneUtil.isValidNumberForRegion(num, selectedRegion)) {
      return selectedRegion;
    }

    return false;
  };

  const handleRegionChange = (name: string, value: string) => {
    setSelectedRegion(value);
    updateWithInput(rawInput, true);
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    setFocused(false);
    updateWithInput(rawInput, true);
    field.onBlur();
  };

  const parseSafe = (number: string, defaultRegion: string): PhoneNumber | null => {
    try {
      return phoneUtil.parse(number, defaultRegion);
    } catch (e) {
      return null;
    }
  };

  const updateWithInput = (input: string, updateRawInput: boolean = false) => {
    const num = parseSafe(input, selectedRegion);

    const numCountryCode = num && num.getCountryCode()?.toString();
    const numRegion = num && phoneUtil.getRegionCodeForNumber(num);
    const numIsValid = num && isValid(num);

    // If we can glean a country code from the number, we might want
    // to change the selected region in the dropdown.
    if (numCountryCode) {
      const guessedRegionOption = regionOptions.find(opt => opt.code === numCountryCode);
      const guessedRegion = (
        guessedRegionOption
        && guessedRegionOption.code !== getCurrentCode()
        && guessedRegionOption.value
      );

      const newRegion = numRegion || guessedRegion;

      if (newRegion) {
        setSelectedRegion(newRegion);
      }
    }

    // Set the Formsy value of the input.
    if (numIsValid) {
      field.onChange(phoneUtil.format(num, PhoneNumberFormat.E164));

      if (updateRawInput) {
        setRawInput(phoneUtil.format(num, PhoneNumberFormat.NATIONAL));
      }
    } else {
      field.onChange(input);

      if (updateRawInput && input !== rawInput) {
        setRawInput(input);
      }
    }
  };

  const renderInput = () => {
    const inputClassname = classnames(
      'form-control',
      props.className,
      {
        'is-invalid': formState.errors[props.name],
      },
    );

    return (
      <div className="phone-input-wrapper">
        <div className="country-select">
          <Select
            name="locale"
            noWrapper
            options={ regionOptions }
            components={ {
              // @ts-ignore
              Option: CustomOption,
              SingleValue: CustomSingleValue,
              IndicatorSeparator: null,
              Menu: CustomMenu,
            } }
            isSearchable={ false }
            isClearable={ false }
            clearable={ false }
            backspaceRemovesValue={ false }
            onChange={ handleRegionChange }
            value={ selectedRegion }
            styles={ {
              menu: styles => {
                return { ...styles, width: 250 };
              },
            } }
            tabIndex={ -1 }
          />
        </div>
        <input
          { ...field }
          ref={ e => {
            field.ref(e);
            ref.current = e;
          } }
          type="tel"
          name={ props.name }
          id={ props.name }
          value={ rawInput }
          onBlur={ handleBlur }
          onFocus={ onFocus }
          onChange={ field.onChange }
          className={ inputClassname }
        />
      </div>
    );
  };

  return (
    <InputWrapper { ...props } { ...formState } focus={ focused }>
      { renderInput() }
    </InputWrapper>
  );
}
