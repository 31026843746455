import { type ChangeEvent, useState } from 'react';

import { useAutoFocus } from 'shared/form/hooks';
import { useInputRegister } from 'shared/form/hooks/useInputRegister';
import InputWrapper from 'shared/form/inputs/InputWrapper';
import { type InputProps, type InputWrapperProps } from 'shared/form/types';

import classnames from 'classnames';
import { get } from 'react-hook-form';

const defaultProps = {
  type: 'text' as const,
};

export default function Input(props: InputProps & InputWrapperProps) {
  props = { ...defaultProps, ...props };

  const [focused, setFocused] = useState(false);
  const { ref } = useAutoFocus(props.initialFocus);

  const { name, type, placeholder, inputProps } = props;

  const { onChange, onBlur, onFocus, ...otherProps } = props;

  const handleOnFocus = (e: ChangeEvent<HTMLInputElement>) => {
    setFocused(true);
    onFocus?.(e);
  };

  const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
    setFocused(false);
    onBlur?.(e);
  };

  // @ts-ignore
  otherProps.onChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(props.name, e.target.value);
  };
  // @ts-ignore
  otherProps.onFocus = handleOnFocus;
  // @ts-ignore
  otherProps.onBlur = handleOnBlur;
  const { field, formState } = useInputRegister(otherProps);

  const renderInput = () => {
    return (
      <input
        { ...field }
        ref={ e => {
          field.ref(e);
          ref.current = e;
          if (props.innerRef) {
            props.innerRef.current = e;
          }
        } }
        type={ type }
        name={ name }
        id={ name }
        onFocus={ e => handleOnFocus(e) }
        className={ classnames('form-control', {
          'is-invalid has-danger': !field.disabled && get(formState.errors, props.name),
        }) }
        placeholder={ placeholder }
        { ...inputProps }
      />
    );
  };

  if (props.type === 'hidden') {
    return renderInput();
  }

  return (
    <InputWrapper { ...props } disabled={ field.disabled } { ...formState } focus={ focused }>
      {renderInput()}
    </InputWrapper>
  );
}

Input.defaultProps = {
  onFocus: () => {},
  innerRef: { current: null },
};
