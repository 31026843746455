import { InputProps } from 'shared/form/types/InputProps';

import { RegisterOptions, UseControllerProps } from 'react-hook-form';


type ControllerKeysType = keyof { [key: string]: UseControllerProps };
const ControllerKeys: ControllerKeysType[] = [
  'name',
  'defaultValue',
  'shouldUnregister',
];

type ValidateObject = {
  [key: string]: any;
};

type TransformedOptions = {
  [key: ControllerKeysType]: any;
}

type OptionKeysType = keyof { [key: string]: RegisterOptions };
export const OptionKeys: Array<OptionKeysType> = [
  'required',
  'maxLength',
  'minLength',
  'max',
  'min',
  'pattern',
  'valueAsNumber',
  'valueAsDate',
  'setValueAs',
  'disabled',
  'onChange',
  'onBlur',
  'value',
  'shouldUnregister',
  'deps',
];

export function propsToController(props: Record<string, InputProps<any>>): UseControllerProps {
  const options: TransformedOptions = {};
  const rules: ValidateObject = {
    validate: {},
  };

  options.defaultValue = props.defaultValue || props.value || '';

  if (props.required) {
    rules.required = 'Required field';
  }

  /**
   * if props has a key that is in the OptionKeys array, then add it to the options object
   * if the key is 'validate', iterate over the object and add each key not found in OptionKeys to the validate object
   */
  Object.keys(props).forEach((key: string): void => {
    if (ControllerKeys.includes(key)) {
      options[key] = props[key];
      return;
    }

    if (key === 'validate') {
      Object.keys(props[key]).forEach((validateKey: string): void => {
        if (!OptionKeys.includes(validateKey)) {
          rules.validate[validateKey] = props.validate[validateKey];
          return;
        }

        rules[validateKey] = props.validate[validateKey];
      });
    }
  });

  return {
    name: props.name,
    ...options,
    rules,
  };
}
