import { Component } from 'react';
import PropTypes from 'prop-types';

import Flag from './Flag';

import { isEqual } from 'lodash';
import { components } from 'react-select';

export default class CustomOption extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.data, nextProps.data);
  }

  render() {
    if (this.props.data.value) {
      return (
        <components.Option { ...this.props }>
          <Flag { ...this.props } />
          <span className="option-label">{ this.props.data.label }</span>
          <span className="option-code">+{ this.props.data.code }</span>
        </components.Option>
      );
    } else {
      return (
        <hr />
      );
    }
  }
}

CustomOption.propTypes = {
  data: PropTypes.object.isRequired,
};
