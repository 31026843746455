import { useAutoFocus } from 'shared/form/hooks';
import { InputProps } from 'shared/form/types';
import { propsToController } from 'shared/form/util/propsToController';

import classnames from 'classnames';
import { useController, useFormContext } from 'react-hook-form';

interface CheckboxProps extends InputProps {
  uncontrolled?: boolean;
  checked?: boolean;
  inline?: boolean;
  labelSmall?: string;
}

export default function Checkbox(props: InputProps<CheckboxProps>) {
  const { ref } = useAutoFocus(props.initialFocus);

  const { name, label, inputProps } = props;
  const { field } = useController(propsToController(props));
  const { formState: { errors } } = useFormContext();

  const errorMessage = errors[props.name];
  const displayErrorMessage = errorMessage?.message;

  const className = classnames(
    'form-group',
    'custom-checkbox',
    'custom-control',
    props.className,
    {
      'disabled': props.disabled,
      'custom-control-inline': props.inline,
      'is-invalid': errorMessage,
    },
  );

  const inputClassname = classnames(
    'form-check-input',
    'custom-control-input',
    props.inputProps?.className,
  );
  const checkboxProps: { [key: string]: any } = { ...inputProps };
  if (props.uncontrolled) {
    checkboxProps.defaultChecked = props.checked ?? field.value;
  } else {
    checkboxProps.checked = props.checked ?? field.value;
  }


  return (
    <div className={ className }>
      <input
        id={ name }
        className={ inputClassname }
        type="checkbox"
        { ...field }
        ref={ e => {
          field.ref(e);
          ref.current = e;
        } }
        { ...checkboxProps } />
      <label className="custom-control-label" htmlFor={ name }> { label }</label>
      { props.labelSmall && <small className="form-text text-muted">{ props.labelSmall }</small> }
      { displayErrorMessage && <div className="form-control-feedback">{ displayErrorMessage }</div> }
    </div>
  );
}
