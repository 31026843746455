import request from 'shared/request';
import { exitApp } from 'store/actions/app';

export function samlLogin(id) {
  return dispatch => {
    return request('api').get('/saml/sso', {
      query: {
        subdomain: id,
      },
    }).then(response => {
      dispatch(exitApp(response.url, {}));
    }).catch(error => {
      let errorMessage = 'There was an error logging in.';
      if (error.response) {
        switch (error.data.code) {
          case 5019:
            errorMessage = 'Please enter the full ID or subdomain for your account.';
            break;
          case 5024:
            errorMessage = 'That account is not configured for single sign-on.';
            break;
        }
      }

      throw new Error(errorMessage);
    });
  };
}
