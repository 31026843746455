import type { TransformedOptions } from 'shared/form/hooks/useInputRegister';
import { type InputProps, type TextareaProps } from 'shared/form/types';

import { useFormContext } from 'react-hook-form';

export default function useDisabledBy(
  props: Record<string, InputProps<any> | TextareaProps<any>>,
  options: TransformedOptions,
) {
  const formContext = useFormContext();

  if (props.enabledBy) {
    options.disabled = true;
  }

  if (props.disabledBy) {
    options.disabled = false;
  }

  const conditionalDisabled = props.enabledBy || props.disabledBy;
  if (conditionalDisabled) {
    const dependencyValue = formContext.watch(conditionalDisabled);

    let fieldEnabled: boolean | null = null;

    if (typeof props.disabledBy === 'string' && dependencyValue) {
      fieldEnabled = false;
    }

    if (typeof props.enabledBy === 'string' && dependencyValue) {
      fieldEnabled = true;
    }

    if (fieldEnabled !== null) {
      options.disabled = !fieldEnabled;
    }
  }

  return options;
}
