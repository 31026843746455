import containsString from './containsString';
import isEmail from './isEmail';
import isFullName from './isFullName';
import isPhoneNumber from './isPhoneNumber';
import maxLength from './maxLength';
import minLength from './minLength';
import passwordScore from './passwordScore';

export default {
  isEmail,
  isFullName,
  isPhoneNumber,
  minLength,
  passwordScore,
  maxLength,
  containsString,
};
